.label-incomplete {
    background-color: #999999;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
}

.label-complete {
    background-color: #5cb85c;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    display: inline;
    padding: 0.2em 0.6em 0.3em;
}

.dashboard-section-incomplete {
    border: 1px solid #ccc;
    padding:20px;
}

.dashboard-section-complete {
    border: 1px solid #ccc;
    background-color: #dff0d8;
    color: #3c763d;
    padding:20px
}

.section-status {
}

.section-footer {
    
}

a {
    text-decoration: none;
    color:#333333;
    font-weight:500
}

a h2:hover {
    color: #006aef;
}


#dashboard-section-complete a h2 {
    color: #333;
    font-size: 18px;
    font-weight:normal
}

#dashboard-section-incomplete a h2 {
    color: #333;
    font-size: 18px;
    font-weight: normal
}

.pull-right {
    float: right !important;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    
}

.btn {  
    font-weight: 400;
    text-align: center;    
    border: 1px solid;    
    padding: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;    
    margin-bottom:6px;
}


