.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.login-submit{
    margin-top:25px;
}
.err-message {
    margin-top: 20px;
    color: red;
}
.login-box {
    background-color: #f0f0f0;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
    padding-top: 2px;
    padding-bottom: 20px;
}
.logos-box {
    width:700px;    
}