.dashboard-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 1em;
}
.dashboard-highlight {
    background-color:#f5f5f5;
    padding:19px;
    margin-bottom:8px;
    margin-top:4px;
}
.dashboard-final {
    background-color: #f5f5f5;
    padding: 19px;
    margin-bottom: 4px;
    margin-top: 8px;
    text-align: center;
    border-color: #999999;
    border-width:1px;
    border-style:solid;
}
