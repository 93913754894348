.sampleCopy{
    font-weight:bold;
    font-size:14px;
    color:#0094ff;    
    margin-bottom:20px
}
.question-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
}
.MuiMenuItem-root{
    background-color:aqua
}
