.logout-wrapper {
    font-size:small;
    font-weight:bold;
    margin-top:40px;  
    clear:both;
    padding-top:20px;
}
#logout-button {
    background-color: white;
    color: black;
    border: 1px solid #E0E0E0;
}

